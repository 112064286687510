import React, { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import { useBooleanPersistence } from "qwanyx";

export const CheckBox = forwardRef(
  ({
    name,
    label = "",
    labelPosition = "right",
    checked = false,
    qwantumId,
    onSave,
    disabled = false
  }, ref) => {
    // Use the dedicated boolean persistence hook
    const { value, handleChange } = useBooleanPersistence(qwantumId, onSave);

    // Initial state comes from persistence or prop
    const [isChecked, setIsChecked] = useState(
      value !== undefined ? value : checked
    );

    // Sync with persistence when it changes
    useEffect(() => {
      if (value !== undefined) {
        setIsChecked(value);
      }
    }, [value]);


    const handleCheckboxChange = (e) => {
      const newValue = e.target.checked;
      setIsChecked(newValue);

      // Use the persistence hook's handler
      handleChange(newValue);
    };

    useImperativeHandle(ref, () => ({
      getValue: () => isChecked,
      isChecked: () => isChecked
    }));

    const renderLabel = () => {
      if (!label) return null;
      return <span style={{ marginLeft: labelPosition === "right" ? "8px" : "0", marginRight: labelPosition === "left" ? "8px" : "0" }}>{label}</span>;
    };

    return (
      <div className="field">
        <div className="control">
          <label className="checkbox" style={{ display: "flex", alignItems: "center" }}>
            {labelPosition === "left" && renderLabel()}
            <input
              name={name}
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
              disabled={disabled}
            />
            {labelPosition === "right" && renderLabel()}
          </label>
        </div>
      </div>
    );
  }
);

export default CheckBox;