import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'bulma/css/bulma.min.css';

export const SearchableList = ({
  name,
  items,
  label,
  placeholder = 'Rechercher...',
  listHeight = 200,
  onSelect
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredItems, setFilteredItems] = useState(items);
  const [isOpen, setIsOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const inputRef = useRef(null);
  const listRef = useRef(null);

  // Filter items based on search term
  useEffect(() => {
    if (searchTerm.trim() === '') {
      setFilteredItems(items);
    } else {
      const normalizedSearchTerm = searchTerm.toLowerCase();
      const filtered = items.filter(item =>
        item.toLowerCase().includes(normalizedSearchTerm)
      );
      setFilteredItems(filtered);
    }
    setHighlightedIndex(-1);
  }, [searchTerm, items]);

  // Scroll to highlighted item
  useEffect(() => {
    if (highlightedIndex >= 0 && listRef.current) {
      const listItem = listRef.current.children[highlightedIndex];
      if (listItem) {
        listItem.scrollIntoView({ block: 'nearest' });
      }
    }
  }, [highlightedIndex]);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    setIsOpen(true);
  };

  const handleItemClick = (item) => {
    setSearchTerm(item);
    setIsOpen(false);
    if (onSelect) {
      onSelect(item);
    }
  };

  const handleKeyDown = (e) => {
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setIsOpen(true);
        setHighlightedIndex(prev =>
          prev < filteredItems.length - 1 ? prev + 1 : prev
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setHighlightedIndex(prev => prev > 0 ? prev - 1 : 0);
        break;
      case 'Enter':
        e.preventDefault();
        if (highlightedIndex >= 0 && filteredItems[highlightedIndex]) {
          handleItemClick(filteredItems[highlightedIndex]);
        }
        break;
      case 'Escape':
        e.preventDefault();
        setIsOpen(false);
        break;
      default:
        break;
    }
  };

  const handleInputFocus = () => {
    setIsOpen(true);
    // Force la mise à jour de la position lorsque l'input reçoit le focus
    updateListPosition();
  };

  const handleClickOutside = (e) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  // Fonction pour mettre à jour la position de la liste
  const updateListPosition = () => {
    // Cette fonction sera appelée pour forcer un re-render et recalculer la position de la liste
    setIsOpen(prev => {
      if (prev) return prev;
      return prev;
    });
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('scroll', updateListPosition);
    window.addEventListener('resize', updateListPosition);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', updateListPosition);
      window.removeEventListener('resize', updateListPosition);
    };
  }, []);

  // Mise à jour de la position de la liste lorsque l'état de l'input change
  useEffect(() => {
    if (isOpen) {
      // Petit délai pour s'assurer que le DOM a été mis à jour
      setTimeout(updateListPosition, 0);
    }
  }, [isOpen, searchTerm]);

  // Référence à l'élément input
  const [inputElement, setInputElement] = useState(null);

  useEffect(() => {
    if (inputRef.current) {
      const input = inputRef.current.querySelector('input');
      setInputElement(input);
    }
  }, []);

  return (
    <div className="field" ref={inputRef}>
      {label && <label className="label">{label}</label>}
      <div className="control">
        <input
          name={name}
          className="input"
          type="text"
          placeholder={placeholder}
          value={searchTerm}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onFocus={handleInputFocus}
        />
      </div>

      {isOpen && filteredItems.length > 0 && (
        <div
          className="menu"
          style={{
            position: 'fixed',
            zIndex: 9999,
            width: inputElement ? inputElement.offsetWidth : 'auto',
            marginTop: '2px',
            boxShadow: '0 2px 3px rgba(10, 10, 10, 0.1)',
            border: '1px solid #dbdbdb',
            borderRadius: '4px',
            background: 'white',
            left: inputElement ? inputElement.getBoundingClientRect().left : 0,
            top: inputElement ? inputElement.getBoundingClientRect().bottom : 0
          }}
        >
          <ul
            className="menu-list"
            ref={listRef}
            style={{
              maxHeight: `${listHeight}px`,
              overflowY: 'auto',
              margin: 0,
              padding: 0
            }}
          >
            {filteredItems.map((item, index) => (
              <li
                key={index}
                onClick={() => handleItemClick(item)}
                style={{
                  padding: '8px 12px',
                  cursor: 'pointer',
                  backgroundColor: highlightedIndex === index ? '#f5f5f5' : 'transparent',
                  borderBottom: index < filteredItems.length - 1 ? '1px solid #eeeeee' : 'none'
                }}
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

SearchableList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  listHeight: PropTypes.number,
  onSelect: PropTypes.func
};

export default SearchableList;