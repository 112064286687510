import React, { useState, useEffect } from "react";
import { useTextPersistence, useBooleanPersistence } from "qwanyx";
// Import your global and local state providers here
// import { useGlobalState } from "./globalStateProvider";
// import { useLocalState } from "./localStateProvider";

// Utility to determine which state hook to use
const useStateSource = (options) => {
  const {
    // Identifiers for different state sources
    qwantumId,     // For persistence (optional)
    globalKey,     // For global state (optional)
    localKey,      // For local state (optional)

    // Default value if no state source has a value
    defaultValue,

    // Type of value for proper conversion
    type = 'text'  // 'text', 'boolean', 'number'
  } = options;

  // IMPORTANT: Call all hooks unconditionally to satisfy React's rules of hooks

  // Always call all hooks, even if we don't use their results
  // This ensures hook call order is consistent across renders

  // Persistence hooks - always call both but only use the appropriate one
  const textPersistence = useTextPersistence(qwantumId || "unused");
  const boolPersistence = useBooleanPersistence(qwantumId || "unused");

  // Get the appropriate persistence hook result based on type
  const persistenceHook = qwantumId
    ? (type === 'boolean' ? boolPersistence : textPersistence)
    : null;

  // Global state hook (placeholder - replace with your implementation)
  // Always call this hook too, even if globalKey is not provided
  // const globalState = useGlobalState(globalKey || "unused"); 
  const globalHook = globalKey ? { value: null, setValue: () => { } } : null;

  // Local state hook (placeholder - replace with your implementation)
  // Always call this hook too, even if localKey is not provided
  // const localState = useLocalState(localKey || "unused");
  const localHook = localKey ? { value: null, setValue: () => { } } : null;

  // Fallback to React useState - always call this
  const [localReactState, setLocalReactState] = useState(defaultValue);

  // Logic to determine which state source to use (priority order)
  let value, setValue;

  if (globalHook && globalKey && globalHook.value !== undefined && globalHook.value !== null) {
    // Use global state
    value = globalHook.value;
    setValue = globalHook.setValue;
  } else if (persistenceHook && qwantumId &&
    (persistenceHook.text !== undefined || persistenceHook.value !== undefined)) {
    // Use persistence
    value = type === 'boolean'
      ? persistenceHook.value
      : persistenceHook.text;
    setValue = type === 'boolean'
      ? persistenceHook.handleChange
      : (val) => {
        const mockEvent = { target: { value: val } };
        persistenceHook.handleBlur(mockEvent);
      };
  } else if (localHook && localKey && localHook.value !== undefined && localHook.value !== null) {
    // Use local state
    value = localHook.value;
    setValue = localHook.setValue;
  } else {
    // Fallback to React state
    value = localReactState;
    setValue = setLocalReactState;
  }

  // Convert value based on type
  let convertedValue = value;
  if (type === 'boolean' && typeof value === 'string') {
    convertedValue = value === 'true';
  } else if (type === 'number' && typeof value === 'string') {
    convertedValue = Number(value);
  }

  return {
    value: convertedValue === undefined ? defaultValue : convertedValue,
    setValue,
    // For event handlers
    handleChange: (e) => {
      const newValue = type === 'boolean' ? e.target.checked : e.target.value;
      setValue(newValue);
    },
    handleBlur: (e) => {
      if (type !== 'boolean' && persistenceHook && qwantumId) {
        persistenceHook.handleBlur(e);
      }
    }
  };
};

// Text Input Component
export const TextInput = ({
  // State source identifiers
  name,
  qwantumId,
  globalKey,
  localKey,
  // Standard props
  value: externalValue,
  defaultValue = "",
  onChange: externalOnChange,
  onBlur: externalOnBlur,
  ...props
}) => {
  const { value, handleChange, handleBlur } = useStateSource({
    qwantumId,
    globalKey,
    localKey,
    defaultValue,
    type: 'text'
  });

  // Component is controlled if value prop is provided
  const isControlled = externalValue !== undefined;
  const currentValue = isControlled ? externalValue : value;

  const onChange = (e) => {
    if (!isControlled) {
      handleChange(e);
    }
    if (externalOnChange) {
      externalOnChange(e);
    }
  };

  const onBlur = (e) => {
    if (!isControlled) {
      handleBlur(e);
    }
    if (externalOnBlur) {
      externalOnBlur(e);
    }
  };

  return (
    <input
      name={name}
      type="text"
      {...props}
      value={currentValue}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

// Email input
export const EmailInput = (props) => <TextInput {...props} type="email" />;

// Password input
export const PasswordInput = (props) => <TextInput {...props} type="password" />;

// Number input
export const NumberInput = ({
  name,
  qwantumId,
  globalKey,
  localKey,
  value: externalValue,
  defaultValue = 0,
  onChange: externalOnChange,
  onBlur: externalOnBlur,
  ...props
}) => {
  const { value, handleChange, handleBlur } = useStateSource({
    qwantumId,
    globalKey,
    localKey,
    defaultValue,
    type: 'number'
  });

  // Component is controlled if value prop is provided
  const isControlled = externalValue !== undefined;
  const currentValue = isControlled ? externalValue : value;

  const onChange = (e) => {
    if (!isControlled) {
      handleChange(e);
    }
    if (externalOnChange) {
      externalOnChange(e);
    }
  };

  const onBlur = (e) => {
    if (!isControlled) {
      handleBlur(e);
    }
    if (externalOnBlur) {
      externalOnBlur(e);
    }
  };

  return (
    <input
      name={name}
      type="number"
      {...props}
      value={currentValue}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

// TextArea component
export const TextAreaInput = ({
  name,
  qwantumId,
  globalKey,
  localKey,
  value: externalValue,
  defaultValue = "",
  onChange: externalOnChange,
  onBlur: externalOnBlur,
  ...props
}) => {
  const { value, handleChange, handleBlur } = useStateSource({
    qwantumId,
    globalKey,
    localKey,
    defaultValue,
    type: 'text'
  });

  // Component is controlled if value prop is provided
  const isControlled = externalValue !== undefined;
  const currentValue = isControlled ? externalValue : value;

  const onChange = (e) => {
    if (!isControlled) {
      handleChange(e);
    }
    if (externalOnChange) {
      externalOnChange(e);
    }
  };

  const onBlur = (e) => {
    if (!isControlled) {
      handleBlur(e);
    }
    if (externalOnBlur) {
      externalOnBlur(e);
    }
  };

  return (
    <textarea
      {...props}
      value={currentValue}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

// Checkbox component
export const Checkbox = ({
  name,
  qwantumId,
  globalKey,
  localKey,
  checked: externalChecked,
  defaultChecked = false,
  onChange: externalOnChange,
  ...props
}) => {
  const { value: checked, handleChange } = useStateSource({
    qwantumId,
    globalKey,
    localKey,
    defaultValue: defaultChecked,
    type: 'boolean'
  });

  // Component is controlled if checked prop is provided
  const isControlled = externalChecked !== undefined;
  const currentChecked = isControlled ? externalChecked : checked;

  const onChange = (e) => {
    if (!isControlled) {
      handleChange(e);
    }
    if (externalOnChange) {
      externalOnChange(e);
    }
  };

  return (
    <input
      name={name}
      type="checkbox"
      {...props}
      checked={currentChecked}
      onChange={onChange}
    />
  );
};

// Radio component
export const Radio = ({
  name,
  qwantumId,
  globalKey,
  localKey,
  value,
  checked: externalChecked,
  onChange: externalOnChange,
  ...props
}) => {
  const { value: selectedValue, setValue } = useStateSource({
    qwantumId,
    globalKey,
    localKey,
    defaultValue: "",
    type: 'text'
  });

  const isChecked = selectedValue === value;

  // Component is controlled if checked prop is provided
  const isControlled = externalChecked !== undefined;
  const currentChecked = isControlled ? externalChecked : isChecked;

  const onChange = (e) => {
    if (!isControlled && e.target.checked) {
      setValue(value);
    }
    if (externalOnChange) {
      externalOnChange(e);
    }
  };

  return (
    <input
      name={name}
      type="radio"
      {...props}
      value={value}
      checked={currentChecked}
      onChange={onChange}
    />
  );
};

// Select component
export const Select = ({
  name,
  qwantumId,
  globalKey,
  localKey,
  value: externalValue,
  defaultValue = "",
  onChange: externalOnChange,
  onBlur: externalOnBlur,
  children,
  ...props
}) => {
  const { value, handleChange, handleBlur } = useStateSource({
    qwantumId,
    globalKey,
    localKey,
    defaultValue,
    type: 'text'
  });

  // Component is controlled if value prop is provided
  const isControlled = externalValue !== undefined;
  const currentValue = isControlled ? externalValue : value;

  const onChange = (e) => {
    if (!isControlled) {
      handleChange(e);
    }
    if (externalOnChange) {
      externalOnChange(e);
    }
  };

  const onBlur = (e) => {
    if (!isControlled) {
      handleBlur(e);
    }
    if (externalOnBlur) {
      externalOnBlur(e);
    }
  };

  return (
    <select
      {...props}
      value={currentValue}
      onChange={onChange}
      onBlur={onBlur}
    >
      {children}
    </select>
  );
};

// Visibility container
export const VisibilityContainer = ({
  qwantumId,
  globalKey,
  localKey,
  defaultVisible = true,
  children,
  className,
  style,
  ...props
}) => {
  const { value: isVisible } = useStateSource({
    qwantumId,
    globalKey,
    localKey,
    defaultValue: defaultVisible,
    type: 'boolean'
  });

  return (
    <div
      {...props}
      className={className}
      style={{
        ...style,
        display: isVisible ? 'block' : 'none'
      }}
      data-visibility={isVisible ? 'visible' : 'hidden'}
    >
      {children}
    </div>
  );
};

// Other specialized inputs can be similarly built using the common pattern above
export const DateInput = (props) => <TextInput {...props} type="date" />;
export const TimeInput = (props) => <TextInput {...props} type="time" />;
export const RangeInput = (props) => <TextInput {...props} type="range" />;
export const ColorInput = (props) => <TextInput {...props} type="color" />;

// File input (no state management for file content itself)
export const FileInput = ({ onChange, ...props }) => (
  <input
    type="file"
    {...props}
    onChange={onChange}
  />
);

// Hidden input 
export const HiddenInput = (props) => <TextInput {...props} type="hidden" />;

// Export as a namespace
export default {
  Text: TextInput,
  Email: EmailInput,
  Password: PasswordInput,
  Number: NumberInput,
  TextArea: TextAreaInput,
  Checkbox: Checkbox,
  Radio: Radio,
  Select: Select,
  Date: DateInput,
  Time: TimeInput,
  File: FileInput,
  Range: RangeInput,
  Color: ColorInput,
  Hidden: HiddenInput,
  Visibility: VisibilityContainer
};