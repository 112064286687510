// componentRegistry.js
// Central registry for all components that can be dynamically rendered

import {
  Textarea,
  EditableText,
  LabelledField,
  LabelledArea,
  LabelledCom,
  JSONEditor,
  Img,
  Invoice,
  CheckBox,
  ImageSection,
  Typo,
  ImageIcon,
  ImageIconGrid,
  Background,
  HTML,
  SearchableList,
  TwinSearchableLists,
  Dock,
  TextInput,
  EmailInput,
  PasswordInput,
  NumberInput,
  TextAreaInput,
  Checkbox,
  Radio,
  Select,
  DateInput,
  TimeInput,
  FileInput,
  RangeInput,
  ColorInput,
  HiddenInput,
  VisibilityContainer,
  Tabs,
  Form,
} from "qwanyx";

// Lazy-loaded component map
// Components will only be imported when needed
export const componentRegistry = {
  get Textarea() { return Textarea; },
  get EditableText() { return EditableText; },
  get LabelledField() { return LabelledField; },
  get LabelledArea() { return LabelledArea; },
  get LabelledCom() { return LabelledCom; },
  get JSONEditor() { return JSONEditor; },
  get Img() { return Img; },
  get Invoice() { return Invoice; },
  get CheckBox() { return CheckBox; },
  get ImageSection() { return ImageSection; },
  get Typo() { return Typo; },
  get ImageIcon() { return ImageIcon; },
  get ImageIconGrid() { return ImageIconGrid; },
  get Background() { return Background; },
  get HTML() { return HTML; },
  get SearchableList() { return SearchableList; },
  get TwinSearchableLists() { return TwinSearchableLists; },
  get Dock() { return Dock; },
  get TextInput() { return TextInput; },
  get EmailInput() { return EmailInput; },
  get PasswordInput() { return PasswordInput; },
  get NumberInput() { return NumberInput; },
  get TextAreaInput() { return TextAreaInput; },
  get Checkbox() { return Checkbox; },
  get Radio() { return Radio; },
  get Select() { return Select; },
  get TimeInput() { return TimeInput; },
  get FileInput() { return FileInput; },
  get RangeInput() { return RangeInput; },
  get ColorInput() { return ColorInput; },
  get HiddenInput() { return HiddenInput; },
  get VisibilityContainer() { return VisibilityContainer; },
  get DateInput() { return DateInput; },
  get Tabs() { return Tabs; },
  get Form() { return Form; },
 
};

// Add a function to register the Card component once it's defined
// (Resolves circular dependency)
export const registerCardComponent = (CardComponent) => {
  Object.defineProperty(componentRegistry, 'Card', {
    get: () => CardComponent,
    configurable: true
  });
};

export default componentRegistry;