import React, { useState, useEffect } from 'react';
import { LabelledField, LabelledImport, Passepartout, useQwanta } from 'qwanyx';

export const Img = ({
    initialSrc = '',
    alt = 'Image',
    objectFit = 'cover',
    width = '100%',
    height = '100%',
    className = '',
    translateX = '0%',
    translateY = '0%',
    crop = 'center',
    backgroundColor = 'black',
    scale = 1,
    rotate = 0,
    labelWidth = '100%',
    style = {},
    labelText = "Image",
    aspectRatio = '1 / 1',
    onSrcChange,
    qwantumId,
    name,
}) => {
    const [persistedValue, setQwanta] = useQwanta(qwantumId);
    const [_id] = useQwanta("_id");
    const [imageUrl, setImageUrl] = useState('');
    const [localValue, setLocalValue] = useState('');
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const s3url = process.env.REACT_APP_S3_URL_BASE;

    useEffect(() => {
        if (persistedValue !== undefined) {
            setLocalValue(persistedValue);
            // Reset imageUrl if persistedValue is empty
            if (!persistedValue) {
                setImageUrl('');
                setCurrentImageIndex(0);
            }
        }
    }, [persistedValue]);

    useEffect(() => {
        if (persistedValue && typeof persistedValue === 'string' && persistedValue.trim() && _id) {
            const images = persistedValue.split(',').map(img => img.trim());
            if (images.length > 0 && currentImageIndex < images.length) {
                const currentImage = images[currentImageIndex];
                const constructedUrl = `${s3url}/${_id}/${currentImage}`;
                setImageUrl(constructedUrl);
            }
        }
    }, [persistedValue, _id, s3url, currentImageIndex]);

    const handleSrcUpdate = (newSrc) => {
        setLocalValue(newSrc);
        setCurrentImageIndex(0);
        
        if (qwantumId) {
            setQwanta(qwantumId, newSrc);
        }
        if (onSrcChange) {
            onSrcChange(newSrc);
        }
    };

    const handleImageClick = () => {
        if (persistedValue) {
            const images = persistedValue.split(',').map(img => img.trim());
            setCurrentImageIndex((prevIndex) => 
                prevIndex + 1 >= images.length ? 0 : prevIndex + 1
            );
        }
    };

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        cursor: 'pointer'
    };

    return (
        <div style={containerStyle}>
        {persistedValue && persistedValue.trim() && (
            <div  
                style={{ 
                    width, 
                    aspectRatio: '1 / 1',  // Ensuring fixed aspect ratio
                    ...style 
                }}  
                onClick={handleImageClick}  
                title="Click to cycle through images"  
            >
                <Passepartout  
                    src={imageUrl}  
                    alt={alt}  
                    objectFit={'contain'}  // Explicitly enforcing 'contain'
                    width={width}  
                    height={height}  
                    className={className}  
                    translateX={translateX}  
                    translateY={translateY}  
                    crop={crop}  
                    backgroundColor={'grey'}  
                    scale={scale}  
                    rotate={rotate}  
                />  
            </div>  
        )}
            <LabelledImport
                name={name}
                iconSize={18}
                fontSize="16px"
                value={localValue}
                labelText="Image url"
                placeholder="Image url..."
                onSave={handleSrcUpdate}
                labelWidth="100%"
                width="100%"
            />
        </div>
    );
};

export default Img;