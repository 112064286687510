import React from "react";
import { Label, Field } from "qwanyx";

export const LabelledField = ({
    fontSize = "16px",
    value = "", // Controlled value for EditableText
    qwantumId = null,
    labelText = "Default Label",
    placeholder = "Enter text here...",
    onSave, // Callback to handle value updates
    labelWidth = "100%", // Dynamic width for the label
    width = "100%",
    closeOnComplete = false,
    editable = true,
    align = "left", // Alignment for the EditableText
    layout = "row", // Layout option: 'row' or 'column'
    labelAlign, // Optional: Override default label alignment
    showColon = true, // Optional colon display
    style = {}, // Additional styles for the container
    name,
}) => {
    // Determine alignment based on layout
    const effectiveLabelAlign = labelAlign || (layout === "column" ? "left" : "right");

    return (
        <div
            style={{
                display: "flex",
                flexDirection: layout, // Set layout dynamically
                justifyContent: "flex-start", // Ensure all items align left
                alignItems: layout === "row" ? "center" : "flex-start", // Adjust vertical alignment
                gap: layout === "column" ? "4px" : "0", // Spacing for column layout
                width: width,
                ...style,
            }}
        >
            {/* Label and Colon */}
            <div
                style={{
                    width: width,
                    display: "flex", // Keep label and colon together
                    alignItems: "center", // Align vertically
                    whiteSpace: "nowrap", // Prevent line breaks
                    minWidth: labelWidth, // Adjust minimum width
                    justifyContent: effectiveLabelAlign === "left" ? "flex-start" : "flex-end",
                    padding: 0, // Remove unnecessary padding
                    margin: 0, // Remove unnecessary margin
                }}
            >
                <Label
                    text={labelText}
                    align={effectiveLabelAlign}
                    style={{
                        fontSize: fontSize,
                        padding: 0,
                        margin: 0,
                    }}
                />
                {showColon && (
                    <span
                        style={{
                            fontSize: fontSize,
                            color: "black",
                            marginLeft: "4px", // Minimal space between label and colon
                            verticalAlign: "middle", // Align colon with text
                        }}
                    >
                        :
                    </span>
                )}
            </div>
            <div
                style={{
                    width: width,
                    flexGrow: 1,
                    //marginLeft: "4px", // Space between label and editable text
                    textAlign: align,
                }}
            >
                <Field
                    //width={width}
                    name={name}
                    fontSize={fontSize}
                    value={value} // Controlled component
                    qwantumId={qwantumId}
                    placeholder={placeholder}
                    onSave={onSave} // Callback to propagate changes
                    closeOnComplete={closeOnComplete}
                    editable={editable}
                    align={align}
                />
            </div>
        </div>
    );
};

export default LabelledField;
