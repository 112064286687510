import React from "react";
import { Label, Textarea } from "qwanyx";

export const LabelledArea = ({
    fontSize = "16px",
    value = "", // Controlled value for EditableText
    qwantumId = null,
    labelText = "Default Label",
    placeholder = "Enter text here...",
    onSave, // Callback to handle value updates
    labelWidth = "100%", // Dynamic width for the label
    width = "100%",
    closeOnComplete = false,
    editable = true,
    align = "left", // Alignment for the EditableText
    layout = "row", // Layout option: 'row' or 'column'
    labelAlign, // Optional: Override default label alignment
    showColon = true, // Optional colon display
    rows = 7,
    style = {}, // Additional styles for the container
    name
}) => {
    return (
        <div className="field" style={{ width, ...style }}>
            {/* Label with optional colon */}
            <label className="label" style={{ fontSize, width: "100%" }}>
                {labelText} {showColon ? ":" : ""}
            </label>
            <Textarea
                name={name}
                fontSize={fontSize}
                rows={rows}
                resize="none"// Control resize behavior (e.g., 'none', 'both', 'horizontal', 'vertical')
                value={value} // Controlled component
                qwantumId={qwantumId}
                placeholder={placeholder}
                onSave={onSave} // Callback to propagate changes
                closeOnComplete={closeOnComplete}
                editable={editable}
                align={align}


            />


        </div>
    );
};

export default LabelledArea;