import { newId } from "qwanyx";

/**
 * Creates a new qwanta instance with form data fields at the same level as other properties
 * @param {string|null} editorId - Editor ID from the provider
 * @param {string|null} frameId - Frame ID from the provider
 * @param {string|null} editorParent - Parent ID
 * @param {string} qwantaType - Type of qwanta (default: "instance")
 * @param {object|null} formData - Form data to be included with the qwanta at the root level
 * @returns {object} The new qwanta instance
 */
export function newQwanta(editorId, frameId, editorParent, qwantaType = "instance", formData = null) {
    const newInstance = {
        _id: newId(),        // Generate a unique ID
        di: frameId,
        pa: editorParent,    // Frame ID from the provider
        ed: editorId,        // Editor ID from the provider
        qwantaType
    };

    // Spread the formData properties directly into the newInstance
    if (formData && typeof formData === 'object') {
        Object.assign(newInstance, formData);
    }

    return newInstance; // Return the new record
}