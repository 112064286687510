import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useQwanta } from "qwanyx";

export const EditableText = ({
    className = '',
    width = '100%',
    defaultText = "", // Fallback text if nothing is provided
    fontSize = "16px",
    placeholder = "Enter text here...",
    onSave,
    align = "left",
    closeOnComplete = true,
    editable = true,
    qwantumId, // Identifier for the state in Qwanta
    name,
}) => {
    const [isEditing, setIsEditing] = useState(false);

    // Get and set the persisted text using Qwanta
    const [savedText, setQwanta] = useQwanta(qwantumId);

    // Local state for immediate changes
    const [localText, setLocalText] = useState(defaultText);

    // Sync `localText` with `savedText` or `defaultText` when `savedText` changes
    useEffect(() => {
        setLocalText(savedText ?? defaultText);
    }, [savedText, defaultText]);

    const saveText = useCallback(() => {
        if (qwantumId && localText !== savedText) { // Check if qwantumId is not null
            setQwanta(qwantumId, localText); // Save the new value in Qwanta
            if (onSave) onSave(localText); // Notify the parent
        }
    }, [localText, savedText, setQwanta, qwantumId, onSave]);


    const handleBlur = useCallback(() => {
        if (closeOnComplete) setIsEditing(false);
        saveText(); // Save changes on blur
    }, [closeOnComplete, saveText]);

    const handleKeyDown = useCallback(
        (event) => {
            if (event.key === "Enter") {
                if (closeOnComplete) setIsEditing(false);
                saveText(); // Save on Enter
            } else if (event.key === "Escape") {
                setIsEditing(false); // Exit editing without saving
            }
        },
        [closeOnComplete, saveText]
    );

    const handleDoubleClick = useCallback(() => {
        setIsEditing(true); // Enable editing on double click
    }, []);

    const handleChange = useCallback((event) => {
        setLocalText(event.target.value); // Update local state as user types
    }, []);

    return useMemo(
        () => (
            <div
                className={className}
                style={{
                    height: '20px',
                    width: width,
                    display: "flex",
                    justifyContent: align === "left" ? "flex-start" : align === "right" ? "flex-end" : "center",
                    alignItems: "center",
                }}
            >
                {isEditing ? (
                    <input
                        name={name}
                        type="text"
                        value={localText}
                        placeholder={placeholder}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyDown}
                        style={{
                            all: "unset",
                            fontSize,
                            //borderBottom: "1px solid black",
                            textAlign: align,
                            width: "100%",
                        }}
                        autoFocus
                    />
                ) : (
                    <div

                        onDoubleClick={handleDoubleClick}
                        style={{
                            fontSize,
                            textAlign: align,
                            cursor: "pointer",
                            width: "100%",
                        }}
                    >
                        {localText || placeholder}
                    </div>
                )}
            </div>
        ),
        [align, fontSize, handleBlur, handleChange, handleDoubleClick, handleKeyDown, isEditing, localText, placeholder]
    );
};

export default EditableText;
