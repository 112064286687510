import React, { useState } from 'react';

export const Tabs = ({ titles, children, padding, backgroundColor }) => {
    const [activeTab, setActiveTab] = useState(0);

    // Ensure children is always an array
    const childrenArray = React.Children.toArray(children);

    return (
        <div>
            {/* Tab navigation with Bulma styling */}
            <div className="tabs is-boxed" >
                <ul>
                    {titles.map((title, index) => (
                        <li
                            
                            key={index}
                            className={index === activeTab ? 'is-active' : ''}
                            data-target={title.toLowerCase().replace(/\s+/g, '-')}
                        >
                            <a onClick={() => setActiveTab(index)}>{title}</a>
                        </li>
                    ))}
                </ul>
            </div>

            {/* Tab content - displayed below the tabs */}
            <div className="tab-content" >
                {childrenArray.map((child, index) => (
                    <div
                        key={index}
                        className="tab-pane"
                        style={{
                            display: index === activeTab ? 'block' : 'none',
                            width: '100%'
                        }}
                    >
                        {child}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Tabs;