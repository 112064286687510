import React, { useRef, useState } from 'react';
import { persistQwanta, newQwanta } from 'qwanyx';

/**
 * Form - A component that handles form data collection, validation and submission internally
 * 
 * This component doesn't create a submit button - it relies on a submit button being included
 * in the children with type="submit"
 * 
 * @param {Object} props
 * @param {ReactNode} props.children - Form elements to render (should include a button with type="submit")
 * @param {string} props.className - Optional class name for the form
 * @param {React.CSSProperties} props.style - Optional styles for the form
 * @param {string} props.apiEndpoint - Optional API endpoint for form submission
 * @param {string} props.method - HTTP method for API call (default: "POST")
 * @param {object} props.validationRules - Object mapping field names to validation rules
 * @param {function} props.onSuccess - Optional callback for successful submission
 * @param {function} props.onError - Optional callback for submission errors
 * @param {string} props.successMessage - Message to display on successful submission
 * @param {boolean} props.persistEmptyValues - Whether to include empty values in the form data (default: false)
 */
export const Form = ({
  name,
  di,
  children,
  className = '',
  style = {},
  apiEndpoint = '',
  method = 'POST',
  validationRules = {},
  onSuccess = null,
  onError = null,
  successMessage = 'Form submitted successfully!',
  persistEmptyValues = false
}) => {
  const formRef = useRef(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Validates a single field based on rules
  const validateField = (name, value, rules) => {
    if (!rules) return true;

    if (rules.required && (!value || (typeof value === 'string' && !value.trim()))) {
      return `${name} is required`;
    }

    if (rules.minLength && value.length < rules.minLength) {
      return `${name} must be at least ${rules.minLength} characters`;
    }

    if (rules.pattern && !new RegExp(rules.pattern).test(value)) {
      return rules.patternMessage || `${name} has an invalid format`;
    }

    if (rules.custom && typeof rules.custom === 'function') {
      const customResult = rules.custom(value);
      if (customResult !== true) {
        return customResult;
      }
    }

    return true;
  };

  // Validates the entire form
  const validateForm = (formData) => {
    for (const fieldName in validationRules) {
      const value = formData[fieldName];
      const rules = validationRules[fieldName];

      const validationResult = validateField(fieldName, value, rules);
      if (validationResult !== true) {
        return { isValid: false, message: validationResult };
      }
    }

    return { isValid: true };
  };

  // Handles form submission, data collection, and validation
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    // Collect all form data
    const formData = {};
    const elements = formRef.current.elements;

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      if (!element.name || element.type === 'submit' || element.type === 'button') continue;

      // Handle different input types
      if (element.type === 'checkbox') {
        formData[element.name] = element.checked;
      } else if (element.type === 'radio') {
        if (element.checked) {
          formData[element.name] = element.value;
        }
      } else if (element.type === 'file') {
        if (element.files.length > 0 || persistEmptyValues) {
          formData[element.name] = element.files;
        }
      } else {
        // For text inputs, textareas, etc. - only include if not empty or persistEmptyValues is true
        const value = element.value;
        const isEmpty = value === '' || (typeof value === 'string' && value.trim() === '');
        
        if (!isEmpty || persistEmptyValues) {
          formData[element.name] = value;
        }
      }
    }

    // Run validation
    const validationResult = validateForm(formData);
    if (!validationResult.isValid) {
      setError(validationResult.message);
      return;
    }

    // Handle submission
    try {
      setIsSubmitting(true);

      if (apiEndpoint) {
        // Submit to API endpoint if provided
        const response = await fetch(apiEndpoint, {
          method,
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });

        if (!response.ok) {
          throw new Error(`Server responded with status: ${response.status}`);
        }

        const responseData = await response.json();
        setSuccess(successMessage);

        if (onSuccess) {
          onSuccess(responseData);
        }
      } else {
        // No API endpoint provided, just handle locally
        const qwantaType = name;
        
        // Create a new qwanta with form data directly at the root level
        const qwantaData = newQwanta(null, di, null, qwantaType, formData);
        await persistQwanta(qwantaData._id, qwantaData);

        console.log('Form data collected:', formData);
        setSuccess(successMessage);

        if (onSuccess) {
          onSuccess(formData);
        }
      }
    } catch (error) {
      setError(`Submission error: ${error.message}`);

      if (onError) {
        onError(error);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form
      ref={formRef}
      onSubmit={handleSubmit}
      className={className}
      style={style}
    >
      {children}

      {error && (
        <div className="form-error" style={{ color: 'red', marginTop: '10px' }}>
          {error}
        </div>
      )}

      {success && (
        <div className="form-success" style={{ color: 'green', marginTop: '10px' }}>
          {success}
        </div>
      )}
    </form>
  );
};

export default Form;